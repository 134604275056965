const SecondsToTime = (secs, type) => {
    let s = secs
    const day = s / (24 * 3600)
    s %= 24 * 3600

    const hour = s / 3600
    s %= 3600
    const minutes = s / 60
    s %= 60
    const seconds = s
    let string = ''
    if (parseInt(day) > 0) {
        string += `${parseInt(day)} Hari, `
    }
    if (parseInt(hour) > 0) {
        string += `${parseInt(hour)} Jam, `
    }
    if (parseInt(minutes) > 0) {
        string += `${parseInt(minutes)} Menit`
    }
    // if (parseInt(seconds) > 0) {
    //     string += `${parseInt(seconds)}s`
    // }
    if (type === 'hours') {
        string =
            secs / 3600 > 1
                ? `${parseInt(secs / 3600)} Jam `
                : `${parseInt(secs / 60)} mnt`
    }
    return string
}
export default SecondsToTime
