import {
    CardAuthor,
    CardContainer,
    CardImage,
    CardTime,
    CardTitle,
    CardSubTitle,
    AudioBookCardImageContainer,
} from './styles'
import compressedImage from '@/helpers/compressedImage'
import SecondsToTime from '@/helpers/secondsToTime'
import { setSegmentName } from '@/store/actions/common'
import Link from 'next/link'
import { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'

const HorizontalCardItem = ({
    id,
    image,
    imageMeta,
    title,
    subTitle,
    videoUrl,
    author,
    duration,
    entityType,
    category,
    viewType,
    sectionTitle,
    isPremium,
    earlyAccessFinishDate,
}) => {
    const dispatch = useDispatch()
    const imageElement = useRef(false)
    const [imageHeight, setImageHeight] = useState('auto')

    const isThumbnail =
        viewType !== 'audiobook' &&
        !['audiobook', 'audioseries'].includes(category)

    useEffect(() => {
        viewType !== 'audiobook' &&
            setImageHeight(imageElement.current.offsetWidth)
    }, [viewType])

    return (
        <CardContainer ref={imageElement}>
            <Link href={`/${entityType}/${id}`} prefetch={false}>
                <a onClick={() => dispatch(setSegmentName(sectionTitle))}>
                    {isThumbnail ? (
                        <CardImage height={imageHeight}>
                            <img
                                width="140px"
                                height="140px"
                                src={compressedImage(image, imageMeta)}
                                alt={title}
                                loading="lazy"
                                style={{
                                    height: '140px',
                                    width: '140px',
                                    borderRadius: '8px',
                                }}
                            />

                            {isPremium && (
                                <img
                                    src={
                                        earlyAccessFinishDate
                                            ? '/img/early_access.svg'
                                            : '/vip.svg'
                                    }
                                    alt="vip badge"
                                    style={{
                                        position: 'absolute',
                                        bottom: '0px',
                                        left: '0px',
                                        borderRadius: '0px',
                                    }}
                                />
                            )}
                        </CardImage>
                    ) : (
                        <AudioBookCardImageContainer
                            height={imageHeight}
                            background={compressedImage(image, imageMeta, true)}
                        >
                            <div
                                height={imageHeight}
                                style={{
                                    width: '100%',
                                    height: imageHeight,
                                    backdropFilter: 'blur(4px)',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '8px',
                                }}
                            >
                                <CardImage
                                    style={{ height: '90%', width: 'auto' }}
                                >
                                    <img
                                        src={compressedImage(
                                            image,
                                            imageMeta,
                                            true
                                        )}
                                        alt={title}
                                        loading="lazy"
                                        height="100%"
                                        width="100%"
                                        style={{
                                            display: 'block',
                                            height: '100%',
                                            width: '100%',
                                        }}
                                    />

                                    {isPremium && (
                                        <img
                                            src={
                                                earlyAccessFinishDate
                                                    ? '/img/early_access.svg'
                                                    : '/vip.svg'
                                            }
                                            alt="vip badge"
                                            style={{
                                                position: 'absolute',
                                                bottom: '0px',
                                                left: '0px',
                                                borderRadius: '0px',
                                            }}
                                        />
                                    )}
                                </CardImage>
                            </div>
                        </AudioBookCardImageContainer>
                    )}

                    <CardTitle>
                        {videoUrl && (
                            <img
                                style={{
                                    width: '16px',
                                    height: '16px',
                                    marginRight: '6px',
                                    marginTop: '-2px',
                                }}
                                alt="video icon"
                                src="/img/video_icon.svg"
                            />
                        )}

                        {title}
                    </CardTitle>

                    <CardSubTitle> {subTitle}</CardSubTitle>
                    <CardAuthor>{author}</CardAuthor>
                    {duration && (
                        <CardTime>
                            {duration < 60
                                ? duration + 's'
                                : SecondsToTime(duration)}
                        </CardTime>
                    )}
                </a>
            </Link>
        </CardContainer>
    )
}

export default HorizontalCardItem
